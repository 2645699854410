// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'highlight.js/styles/atom-one-dark.css';

@import "~font-awesome/scss/font-awesome";

html{
    position: relative;
    min-height: 100%;
}

body{
    //background-color: rgba(0, 0, 0, .2);
    //background-image:  linear-gradient(#d2d2d2 2px, transparent 2px), linear-gradient(90deg, #bbbbbb 2px, transparent 2px), linear-gradient(#c8c8c8 1px, transparent 1px), linear-gradient(90deg, #bebebe 1px, #e5e5f7 1px);
    //background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
    //background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
    background-color: #f5f5f5;
    background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(255, 255, 255, 0.76) 3px, rgba(255, 255, 255, 0.73) 10px);
    font-size: 1.1rem;
}

.form-group{
    input{
        background-color: #fff;
    }
}

/* The switch - the box around the slider */
#theme-toggle-button {
    font-size: 7px;
    position: relative;
    display: inline-block;
    width: 8em;
    cursor: pointer;
    margin-top: -6px;
}

/* Hide default HTML checkbox */
#toggle {
    opacity: 0;
    width: 0;
    height: 0;
}

#container,
#patches,
#stars,
#button,
#sun,
#moon,
#cloud {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.25s;
}

/* night sky background */
#toggle:checked + svg #container {
    fill: #2b4360;
}

/* move button to right when checked */
#toggle:checked + svg #button {
    transform: translate(28px, 2.333px);
}

/* show/hide sun and moon based on checkbox state */
#sun {
    opacity: 1;
}

#toggle:checked + svg #sun {
    opacity: 0;
}

#moon {
    opacity: 0;
}

#toggle:checked + svg #moon {
    opacity: 1;
}

/* show or hide background items on checkbox state */
#cloud {
    opacity: 1;
}

#toggle:checked + svg #cloud {
    opacity: 0;
}

#stars {
    opacity: 0;
}

#toggle:checked + svg #stars {
    opacity: 1;
}


.navbar-brand{
    .code-element{
        color: black;
    }
    .logo-blue-element{
        color: blue;
    }
    .logo-orange-element{
        color: orange;
    }
}

.card{
    border-radius: 0;
    box-shadow: 2px 3px 7px #bdbcbc;
    &.category-title{
        background: rgba(255, 255, 255, 0.4);
        border-color: rgba(255, 255, 255, 0.6);
    }
}

.snippet{
    border-bottom: 1px dashed #ccc;
    padding: 10px 0;
}

footer{
    position: absolute;
    width: 100%;
    bottom: 0;
    margin-bottom: -20%;
    .courses{
        background: #1a202c;
        color: #fff;
        padding: 15px;
        text-align: center;
    }
    .colophone{
        background: #3f4963;
        color: #fff;
        padding: 15px;
    }
    .copyright{
        background: #1a202c;
        color: #fff;
        padding: 15px;
        text-align: center;
    }
}

.lesson{
    border: 1px solid #ccc;
    margin: 15px 0;
    padding: 15px;
    border-radius: 5px;
    &:hover{
        box-shadow: 2px 3px 7px #bdbcbc;
    }
}

.widget{
    a{
        text-decoration: none;
        &:hover{
            text-shadow: 1px 1px 1px #ccc;
        }
    }
    ul{
        padding-left: 0;
        list-style: none;
        li{
            border-bottom: 1px dashed #ccc;
        }
    }
}

.messages{
    max-height: 350px;
    background: #ccc;
    overflow-y: auto;
    border: 1px solid #c9c8c8;
    padding: 5px;
    display: flex;
    flex-direction: column-reverse;
}
.messages .message:nth-child(odd) {
    background-color: #e5e5e5; /* Set the background color for odd elements */
}

.widget-auth{
    background-image: url('/img/lodge1-min.png');

    padding: 15px;
    border-radius: 5px;
    box-shadow: 2px 3px 7px #bdbcbc;
    .user-info{
        text-align: center;
        img{
            border-radius: 4px;
            border: 1px solid #ccc;
            box-shadow: 1px 2px 5px #000;
        }
        .username{
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #8a8a8a;
            font-size: 24px;
            color: #000;
            &:hover{
                text-shadow: 1px 1px 1px black;
            }
        }
        .meta{

        }
    }
    .links{
        background: rgba(255, 255, 255, .4);
        ul{
            list-style: none;
            li{
                a{
                    text-decoration: none;
                    color: black;
                    &:hover{
                        text-shadow: 1px 1px 1px black;
                    }
                }
            }
        }
    }
}

.recommendation{
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 5px;
    img{
        border-radius: 5px;
        border: 1px solid #6cb2eb;
    }
    .title{
        text-decoration: none;
        font-weight: 600;
        &:hover{
            text-shadow: 1px 1px 1px #ccc;
        }
    }
    hr{
        margin: 0.4rem 0;
        border-top: 1px dashed #ccc;
    }
    .meta{
        a{
            text-decoration: none;
            color: #3f3f3f;
            &:hover{
                text-shadow: 1px 1px 1px #ccc;
            }
        }
    }
    .likes{
        margin-top: 5px;
        .showLikes{
            text-decoration: none;
            color: #3f3f3f;
            &:hover{
                text-shadow: 1px 1px 1px #ccc;
            }
        }
        .likeBtn{
            background-color: #d1ebff;
            border-color: #6cb2eb;
            &:hover{
                background-color: #6cb2eb;
                box-shadow: 1px 1px 1px #ccc;
            }
        }
    }
}

.with-dashed-separator{
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #ccc;
}

.card{
    .card-header{
        border-bottom: 0;
    }
    .card-footer{
        border-top: 0;
    }
}

.forum-page-heading{
    background: rgba(255, 255, 255, .4);
    .input-group-text{
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        input{
            background: rgb(248, 249, 250);
            border: 0;
        }
    }
}

.btn-forum-display-type{
    text-decoration: none;
    border: 1px solid rgba(45, 41, 41, 0.4);
    background: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
    padding-left: 4px;
    padding-right: 4px;
}

.forum-post-author{
    text-align: center;
    border-right: 1px solid #dcdee1;
    .username{
        text-decoration: none;
    }
    img{
        border-radius: 2px;
        border: 1px solid #ccc;
        box-shadow: 2px 3px 7px #bdbcbc;
    }
}

.forum-post{
    hr{
        margin: 0;
    }
}

.discussion{
    h1{
        font-size: 1.75rem;
    //    font-family
    }
    .meta{
        background: rgba(255, 255, 255, 0.5);
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 2px 3px 7px #bdbcbc;
    }
}

.forum-row{
    border-bottom: 1px dashed #ccc;
    padding-top: 15px;
    .author{
        text-decoration: none;
        img{
            border-radius: 15px;
            margin-right: 5px;
        }
    }
}

.theme-switcher{
    /* The switch - the box around the slider */
    .switch {
        margin-top: 4px;
        position: relative;
        display: inline-block;
        width: 60px;
        height: 28px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "☼";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        text-align: center;
    }

    input:checked + .slider {
        background-color: #51585E;
    //     background image cu luna
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.bm-btn{
    color: white;
    text-decoration: none;
    border-radius: 3px;
    font-size: 10px;
    padding: 2px;
    &.bookmark-rm-btn{
        background: #f34040;
        border: 1px solid #4d0209;
    }
    &.bookmark-add-btn{
        background: green;
        border: 1px solid #015b03;
    }
    &:hover{
        box-shadow: 1px 1px 1px #000;
    }
}
.uc-peasant{
    color:  #eab821!important;
}
.uc-user{
    color: #21bbea!important;
}
.uc-power_user{
    color: #f05307!important;
}
.uc-elite_user{
    color: #6dce41!important;
}
.uc-xtreme_user{
    color: #41cec1!important;
}
.uc-super_user{
    font-weight: 600;
    color: #219700!important;
}
.uc-legendary_user{
    font-weight: 600;
    color: #73756b!important;
}
.uc-star{
    font-weight: 700;
    color: #fd06f9!important;
}
.uc-vip{
    font-weight: 700;
    color: #3883d2!important;
}
.uc-codemaster{
    font-weight: 700;
    color: #1869bf!important;
}
.uc-guru{
    font-weight: 700;
    text-shadow: 1px 1px 1px #ccc;
    color: #ff0000!important;
}

.addSmiley{
    cursor: pointer;
}

.article{
    .card-header a{
        font-size: 1.2rem;
        text-decoration: none;
        font-weight: 600;
        &:hover{
            text-shadow: 1px 1px 1px #ccc;
        }
    }
}
.userContact{
    a{
        text-decoration: none;
        font-size: 1.2rem;
        &:hover{
            text-shadow: 1px 1px 1px #ccc;
        }
    }
}
.educationList{
    .education{
        border-bottom: 1px dashed #ccc;
        div{
            border-right: 1px dashed #ccc;
        }
    }
}

.jobList{
    .job{
        border-bottom: 1px dashed #ccc;
        div{
            border-right: 1px dashed #ccc;
        }
    }
}

.hidden{
    display: none;
}

.snippet-search{
    button{
        background: none;
        border: none;
    }
}

.widgetCollapse{
    text-decoration: none;
}

.dark{
    body{
        background-color: #6f6f6f;
        background-image: repeating-linear-gradient(135deg, transparent, transparent 3px, rgba(136, 136, 136, 0.76) 3px, rgba(119, 119, 119, 0.73) 10px);
        font-size: 1.1rem;
    }
    input, select{
        background-color: #9b9797;
        border-color: #3e3d3d;
        &:active, &:focus{
            background-color: #8f8a8a;
        }
    }
    textarea{
        background-color: #9b9797;
        border-color: #3e3d3d;
        &:active, &:focus{
            background-color: #8f8a8a;
        }
    }
    .input-group-text{
        background-color: #9b9797;
        border-color: #3e3d3d;
        input[type="submit"]{
            background-color: #9b9797;
            color: white;
        }
    }
    .card{
        border-radius: 0;
        box-shadow: 2px 3px 7px #4a4a4a;
        border-color: #4a4a4a;
        .card-header{
            border-radius: 0;
            background-color: #3e3d3d;
            color: white;
        }
        .card-body{
            background-color: #7e7e7e;
            border-radius: 0;
            color: white;
            a{
                color: white;
            }
        }
        .card-footer{
            background-color: #454444;
            color: white;
            border-radius: 0;
        }
    }
    .btn-forum-display-type{
        background: #777777;
    }
    .discussion{
        .meta{
            background: rgba(183, 183, 183, 0.5);
            padding: 5px;
            border: 1px solid #737171;
            border-radius: 5px;
            box-shadow: 2px 3px 7px #bdbcbc;
            a{
                color: white;
            }
        }
    }
    .recommendation{
        .likeBtn{
            background-color: #a8a8a8;
            border-color: #ccc;
            &:hover{
                background-color: #6cb2eb;
                box-shadow: 1px 1px 1px #ccc;
            }
        }
    }
    .likes-modal{
        .modal-content{
            .modal-header{
                background: #3e3d3d;
                color: white;
            }
            .modal-body{
                background: #7e7e7e;
                a{
                    color: white;
                }
            }
        }
    }
    .messages{
        max-height: 350px;
        background: #9b9797;
        overflow-y: auto;
        border: 1px solid #c9c8c8;
        padding: 5px;
        display: flex;
        flex-direction: column-reverse;
    }
    .messages .message:nth-child(odd) {
        background-color: #7c7c7c; /* Set the background color for odd elements */
    }
    .EasyMDEContainer{
        .CodeMirror{
            background-color: #9b9797;
        }
    }
    .pagination{
        li{
            a{
                background: #797979;
                color: white;
                border-color: #3e3d3d;
            }
            &.disabled{
                span{
                    background: #797979;
                    color: white;
                    border-color: #3e3d3d;
                }
            }
            &.active{
                span{
                    background: #3490dc;
                    color: white;
                    border-color: #3e3d3d;
                }
            }
        }
    }
    .bookmarks-page{
        .table{
            tr{
                th{
                    background-color: #6f6e6e;
                }
                td{
                    background-color: #979797;
                    border-right: 1px dashed #ccc;
                    &:last-child{
                        border-right: 0;
                    }
                }
            }
        }
    }
    table {
        thead{
            tr{
                td{

                }
                th{
                    background-color: #3e3d3d;
                    color: #fff;
                }
            }
        }
        tbody{
            tr{
                td{
                    background-color: #a3a3a6;
                }
            }
        }
    }
    .btn{
        color: black!important;
    }
}
.forums{
    .title{
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;
        &:hover{
            text-shadow: 1px 1px 1px #ccc;
        }
    }
    .avatar{
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .excerpt{
        .meta{
            font-size: .7rem;
        }
    }
    .channel-name{
        text-decoration: none;
        padding: 3px 5px;
        border-radius: 5px;
    }
    .channel-laravel{
        border: 1px solid slateblue;
        &:hover{
            background-color: slateblue;
            color: white!important;
        }
    }
    .channel-php{
        border: 1px solid seagreen;
        &:hover{
            background-color: seagreen;
            color: white!important;
        }
    }
    .channel-general{
        border: 1px solid red;
        &:hover{
            background-color: red;
            color: white!important;
        }
    }
    .channel-servers{
        border: 1px solid rebeccapurple;
        &:hover{
            background-color: rebeccapurple;
            color: white!important;
        }
    }
    .channel-feedback{
        border: 1px solid blue;
        &:hover{
            background-color: blue;
            color: white!important;
        }
    }
    .channel-help{
        border: 1px solid green;
        &:hover{
            background-color: green;
            color: white!important;
        }
    }
    .channel-eloquent{
        border: 1px solid violet;
        &:hover{
            background-color: violet;
            color: white!important;
        }
    }
    .channel-doctrine{
        border: 1px solid pink;
        &:hover{
            background-color: pink;
            color: white!important;
        }
    }
    .channel-sql{
        border: 1px solid orange;
        &:hover{
            background-color: orange;
            color: white!important;
        }
    }
    .channel-symfony{
        border: 1px solid firebrick;
        &:hover{
            background-color: firebrick;
            color: white!important;
        }
    }
    .channel-wordpress{
        border: 1px solid mediumvioletred;
        &:hover{
            background-color: mediumvioletred;
            color: white!important;
        }
    }
    .channel-prestashop{
        border: 1px solid brown;
        &:hover{
            background-color: brown;
            color: white!important;
        }
    }
    .channel-javascript{
        border: 1px solid dodgerblue;
        &:hover{
            background-color: dodgerblue;
            color: white!important;
        }
    }
    .channel-testing{
        border: 1px solid darkolivegreen;
        &:hover{
            background-color: darkolivegreen;
            color: white!important;
        }
    }
    .channel-tips-and-tricks{
        border: 1px solid midnightblue;
        &:hover{
            background-color: midnightblue;
            color: white!important;
        }
    }
}
.friendship{
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
    &:hover{
        box-shadow: 2px 3px 7px #bdbcbc;
    }
}

.frienships-btn{
    border: 1px solid #ccc;
    border-radius: 5px;
    text-decoration: none;
    padding: 2px 5px;
    &:hover{
        background-color: #ccc;
        box-shadow: 2px 3px 7px #bdbcbc;
    }
}

.toast{
    position: absolute;
    top: 15px;
    right: 15px;
    display:inline-block!important;
    &.success{
        background-color: rgba(50, 205, 50, .4);
    }
    &.error{
        background-color: rgba(241, 12, 33, 0.4);
    }
    .close{
        position: absolute;
        right: 15px;
        text-decoration: none;
        font-size: 18px;
        color: black;
    }
}
.error404{
    h1{
        margin-top: 45px;
        color: orange;
    }
    .heading{
        font-size: 2rem;
        display: block;
    }
    .subheading{

    }
}

.gdpr-bar{
    width: 100%;
    position: fixed;
    bottom: 0;
    background: white;
    border-top: 1px solid #ccc;
    p{
        margin-top: 10px;
    }
    a{
        margin-top: 10px;
    }
}

.hide{
    display: none!important;
}

.backToTop{
    display: inline;
    position: fixed;
    font-size: 2rem;
    cursor: pointer;
    right: 15px;
    bottom: 0;
}

.badge-info{
    background: #1d4bbf!important;
}

.notifications{
    width: 20rem;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
        border-bottom: 1px solid #ccc;
        padding: 0 5px;
    }
}

.notification-row{
    border-bottom: 1px dashed #ccc;
    padding: 5px 0;
}
